<template>
  <v-dialog v-if="localValue" v-model="localValue" width="500">
    <v-card>
      <v-card-title>
        <div>
          <v-btn small absolute top right icon @click="localValue = false">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <h4 style="color:#024059" class="ml-0 mb-4">
          Detalhes do pagamento
          <v-icon color="#FF9800" v-if="$vuetify.breakpoint.smAndUp"
            >receipt_long</v-icon
          >
        </h4>
      </v-card-title>
      <v-card-text>
        <v-row class="px-4">
          <p class="ml-0" style="font-size:16px">
            A {{ detailsTaxesProps.detail }} encontra-se paga
          </p>
          <ul>
            <li class="mb-4">
              Competencia:
              <span class="font-weight-bold">
                {{ detailsTaxesProps.dateReference | moment('MMMM/Y') }}
              </span>
            </li>
            <li class="mb-4">
              Data de confirmação do pagamento:
              <span class="font-weight-bold">{{
                detailsTaxesProps.paidAt | moment('DD/MM/YYYY')
              }}</span>
            </li>
          </ul>
          <div v-if="!hasDocument">
            <ul>
              <li class="mb-4">
                Caso deseje enviar um comprovante clique em "Selecionar Arquivo"
              </li>
            </ul>
            <v-row no-gutters class="ml-2">
              <buttonFile
                v-model="detailDocument.file"
                class="mr-2 mb-2"
              ></buttonFile>
              <v-btn
                dark
                color="#024059"
                :loading="loading"
                @click="sendDocument()"
                >Salvar</v-btn
              >
            </v-row>
          </div>
          <v-row no-gutters v-else class="px-3">
            <v-btn
              :block="$vuetify.breakpoint.smAndDown ? true : false"
              color="#024059"
              dark
              class="mr-2 mb-2"
              target="_blank"
              :href="urlToDonload(detailsTaxesProps.receiptFileId)"
            >
              Ver comprovante
            </v-btn>

            <v-btn
              color="#024059"
              outlined
              @click="hasDocument = !hasDocument"
              :block="$vuetify.breakpoint.smAndDown ? true : false"
              >Alterar</v-btn
            >
          </v-row>
          <span style="color: #F44336" class="pl-2" v-if="textNoFile"
            >Selecione um arquivo para o envio.</span
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fileMethods } from '@state/helpers'
import buttonFile from './buttonFile.vue'

export default {
  components: {
    buttonFile,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    detailsTaxesProps: {
      type: Object,
    },
  },
  data() {
    return {
      textNoFile: false,
      detailDocument: {
        date: null,
        file: null,
      },
      progress: false,
      icon: 'fa-arrow-alt-circle-up',
      loading: false,
      valid: false,
      hasDocument: false,
      viewReceipt: false,
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...fileMethods,

    sendDocument() {
      if (this.detailDocument.file) {
        this.loading = true
        this.detailDocument.date = this.detailsTaxesProps.paidAt
        this.$store
          .dispatch('taxations/updateTax', {
            id: this.detailsTaxesProps.id,
            paidAt: this.detailDocument.date,
            file: this.detailDocument.file,
          })
          .then((resp) => {
            this.loading = false
            this.localValue = false
          })
      } else {
        this.textNoFile = true
      }
    },
  },
  created() {
    if (this.detailsTaxesProps.receiptFileId != null) {
      this.hasDocument = true
    }
  },
}
</script>
