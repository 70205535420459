<template>
  <v-dialog v-model="localValue" width="600px">
    <v-card
      :class="$vuetify.breakpoint.smAndDown ? 'px-3' : 'text-xs-end  px-4'"
    >
      <v-card-title style=" word-break: break-word">
        <div>
          <v-btn small absolute top right icon @click="localValue = false">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <h4 style="color:#024059" class="ml-0 mb-4">
          Confirme seu pagamento
          <v-icon color="#FF9800" v-if="$vuetify.breakpoint.smAndUp">
            library_add_check
          </v-icon>
        </h4>
      </v-card-title>
      <v-card-text>
        <v-col
          class="ma-0 pa-0"
          cols="12"
          sm="12"
          md="12"
          v-if="dataTaxesProps.idStatus != 3"
        >
          <v-row justify="space-between" class="px-3">
            <h3 class="mb-0">{{ dataTaxesProps.detail }}</h3>
            <h3 class="mb-0"
              >Valor {{ dataTaxesProps.value | currencyFromCents }}</h3
            >
          </v-row>
          <v-divider class="mt-3"></v-divider>
        </v-col>

        <v-col
          cols="12"
          sm="8"
          md="8"
          class="mt-4"
          v-if="dataTaxesProps.idStatus != 3"
        >
          <v-row justify="space-between" align="center">
            <p class="font-weight-bold">Competência</p>
            <p>{{ dataTaxesProps.dateReference | moment('MMMM/Y') }}</p>
          </v-row>

          <v-row justify="space-between" align="center">
            <p class="font-weight-bold">Vencimento</p>
            <p>{{ dataTaxesProps.dueAt | moment('DD/MM/YYYY') }}</p>
          </v-row>
        </v-col>

        <v-row no-gutters justify="start" v-else>
          <v-col cols="11">
            <p style="font-size:15px" class="font-weight-bold">
              A {{ dataTaxesProps.detail }} da competência de
              {{ dataTaxesProps.dateReference | moment('MMMM/Y') }}
              encontra-se vencida desde o dia
              {{ dataTaxesProps.dueAt | moment('DD/MM/YYYY') }}.
            </p>
          </v-col>
          <v-col cols="12">
            <p style="font-size:15px">
              Caso tenha efetuado o pagamento, informe a data e envie o
              comprovante clicando em "Selecionar Arquivo" para atualizar o
              status.
            </p>
          </v-col>
        </v-row>

        <v-col class="ma-0 pa-0" cols="12">
          <v-row
            :class="$vuetify.breakpoint.smAndUp ? 'align-center' : 'column'"
          >
            <v-col
              :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'"
              cols="12"
              sm="6"
              md="6"
            >
              <p class="font-weight-bold mb-0">Data de Pagamento: </p>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <input-date
                v-model="dataForm.date"
                :input="{ placeholder: 'SELECIONE A DATA' }"
              ></input-date>
            </v-col>
          </v-row>
          <v-row
            justify="space-between"
            :class="$vuetify.breakpoint.smAndUp ? 'align-center' : 'column'"
          >
            <v-col
              :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'"
              cols="12"
              sm="6"
              md="6"
              class="pt-5"
            >
              <p class="font-weight-bold mb-0">Comprovante (Foto/PDF):</p>
              <p
                style="font-size:12px"
                :class="$vuetify.breakpoint.smAndUp ? '' : 'ma-0 pa-0'"
                >O arquivo não pode ultrapassar 60MB.</p
              >
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <buttonFile v-model="dataForm.file"></buttonFile>
            </v-col>
          </v-row>
        </v-col>
        <v-row justify="space-between" class="mt-3 pt-5 px-3">
          <v-btn
            dark
            class="mb-2"
            color="#024059"
            @click="localValue = false"
            :block="$vuetify.breakpoint.smAndDown ? true : false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="mr-6 mb-2"
            outlined
            color="#024059"
            :loading="loading"
            :disabled="
              dataTaxesProps.idStatus != 3
                ? dataForm.date == ''
                : dataForm.file == null || dataForm.date == ''
            "
            @click="sendDocument()"
            :block="$vuetify.breakpoint.smAndDown ? true : false"
          >
            Confirmar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import inputDate from '@components/input/inputDateBtn.vue'
import buttonFile from './buttonFile.vue'
export default {
  components: {
    inputDate,
    buttonFile,
  },
  data() {
    return {
      loading: false,
      viewReceipt: false,
      dataForm: {
        date: '',
        file: null,
      },
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dataTaxesProps: {
      type: Object,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    sendDocument() {
      this.loading = true
      this.$store
        .dispatch('taxations/updateTax', {
          id: this.dataTaxesProps.id,
          paidAt: this.dataForm.date,
          file: this.dataForm.file,
        })
        .then((resp) => {
          this.loading = false
          this.localValue = false
        })
    },
  },
}
</script>
