<template>
  <v-container fluid>
    <v-row>
      <v-subheader>Gerenciar Impostos</v-subheader>
      <v-card width="100%">
        <base-header-Information :title="headerTitle" color="orange">
          <template slot="description">
            <p>
              {{ headerDescription }}
            </p>
          </template>
        </base-header-Information>
        <v-container class="wrap justify" fluid>
          <!-- card de filtros e total de impostos -->
          <v-row align="center" justify="space-between" class="mt-3 mb-8">
            <v-col cols="12">
              <v-card align-center>
                <v-card-title primary-title class="justify-space-between">
                  Filtros

                  <v-btn
                    small
                    color="#607D8B"
                    dark
                    outlined
                    @click="seeMoreFilters = !seeMoreFilters"
                    class="px-1"
                  >
                    <v-icon class="mr-1">filter_alt</v-icon>
                    {{
                      seeMoreFilters ? 'menos filtros' : 'mais filtros'
                    }}</v-btn
                  >
                </v-card-title>

                <!-- filtros -->
                <v-row no-gutters align="center" justify="start" class="ml-1">
                  <!-- filtro de mes e ano -->
                  <v-col cols="12" md="4" lg="4" sm="12" class="pl-3">
                    <select-month
                      :yearMonth.sync="selectedMonth"
                      :select="{ label: 'Mês de vencimento' }"
                    ></select-month>
                  </v-col>

                  <!-- filtro de status dos impostos -->
                  <v-col cols="12" md="5" lg="5" sm="12" class="px-0">
                    <v-expand-transition>
                      <div class="px-0" v-show="seeMoreFilters">
                        <v-card-text class="pa-0">
                          <span
                            class="caption text--primary font-weight-light mx-2"
                            >Selecione o status</span
                          >
                          <v-row no-gutters class="mb-4">
                            <div
                              v-for="(item, key) in states"
                              :key="item.name"
                              class="d-flex flex-column align-center justify-center"
                            >
                              <v-checkbox
                                class="ml-3 ma-0"
                                small
                                :color="item.color"
                                :value="item.name"
                                v-model="stateSelected"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="pa-0 ma-0"
                                    :style="
                                      $vuetify.breakpoint.smAndDown
                                        ? `font-size: 13px`
                                        : ``
                                    "
                                    >{{ item.name }}</span
                                  >
                                </template>
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-col>
                </v-row>

                <!-- legenda e total de impostos -->
                <v-divider class="mx-2 mt-2"></v-divider>
                <v-card-title class="justify-space-between">
                  <span class="subtitle-2 font-weight-medium">
                    Total de Impostos Filtrados
                  </span>

                  <v-row
                    :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'my-2'"
                    align="center"
                    style=" border-radius: 15px;"
                    :justify="$vuetify.breakpoint.smAndUp ? 'end' : 'start'"
                  >
                    <div
                      v-for="(item, key) in states"
                      :key="item.name"
                      class="mx-2 d-flex flex-column align-center justify-center"
                    >
                      <span
                        class="body-2 text--secondary font-weight-light mr-2"
                      >
                        <v-icon small :color="item.color" class="mr-2"
                          >fa-circle</v-icon
                        >{{ item.name }} - Total ({{ totalState[key] }})
                      </span>
                    </div>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <!-- listagem de impostos -->
          <v-row v-for="(item, index) in filteredItems" :key="index">
            <v-row class="mb-14 mx-1" align="center" justify="center">
              <v-col class="pa-0">
                <div class="d-flex flex-row align-center justify-center px-1">
                  <span class="title text--secondary font-weight-light"
                    >Vencimento:
                    {{ listDateDueTaxes[index] | moment('MMMM/Y') }}</span
                  >
                  <v-divider class=" ml-2"></v-divider>
                </div>
              </v-col>

              <v-col cols="12" class="tax-due" style="border-radius: 5px;">
                <v-data-iterator
                  :items="item"
                  item-key="name"
                  :items-per-page.sync="rowsPerPage"
                  row
                  wrap
                  hide-default-footer
                  style="width:100%"
                >
                  <template v-slot:default="props">
                    <v-row no-gutters dense>
                      <v-col
                        class="d-flex text-center justify-center my-10"
                        v-if="loadingTaxes"
                        cols="12"
                      >
                        <v-progress-circular
                          :size="50"
                          width="3"
                          color="#0288D1"
                          indeterminate
                        ></v-progress-circular>
                      </v-col>
                      <v-col
                        v-else
                        v-for="item in props.items"
                        :key="item.id"
                        cols="12"
                        md="4"
                        sm="6"
                        :class="
                          $vuetify.breakpoint.smAndDown
                            ? 'mb-8 pa-2'
                            : 'mb-6 pr-3'
                        "
                      >
                        <v-card
                          :class="cardClass(item.state)"
                          height="100%"
                          :max-width="
                            $vuetify.breakpoint.smAndDown ? '100%' : '85%'
                          "
                          min-width="100px"
                          elevation="5"
                        >
                          <v-row
                            align="center"
                            justify="end"
                            absolute
                            top
                            right
                            class="pr-2 pt-1"
                          >
                            <v-icon :color="item.stateColor">lens</v-icon>
                          </v-row>
                          <v-card-title class="pt-0 pb-1">
                            <h4 style="word-break: break-word">{{
                              item.detail
                            }}</h4>
                          </v-card-title>
                          <v-card-text>
                            <v-col cols="12" class="text-xs-start">
                              <v-row justify="space-between">
                                Vencimento
                                <span>
                                  {{ item.dueAt | moment('DD/MM/YYYY') }}
                                </span>
                              </v-row>
                              <v-row justify="space-between">
                                Valor
                                <span>
                                  {{ item.value | currencyFromCents }}
                                </span>
                              </v-row>
                              <v-row justify="space-between">
                                competência:
                                <span class="font-weight-bold">
                                  {{ item.dateReference | moment('MMMM/Y') }}
                                </span>
                              </v-row>
                            </v-col>
                          </v-card-text>
                          <v-card-text>
                            <v-row
                              :class="
                                $vuetify.breakpoint.smAndDown
                                  ? 'd-flex flex-column'
                                  : ''
                              "
                            >
                              <div
                                class="mr-2 mb-2"
                                v-if="isPaid(item.state) || isOpen(item.state)"
                              >
                                <v-btn
                                  :block="
                                    $vuetify.breakpoint.smAndDown ? true : false
                                  "
                                  class="btn"
                                  small
                                  outlined
                                  download
                                  target="_blank"
                                  :href="urlToDonload(item.idFile)"
                                >
                                  Baixar
                                </v-btn>
                              </div>
                              <div class="mr-2 mb-2" v-if="isPaid(item.state)">
                                <v-btn
                                  :block="
                                    $vuetify.breakpoint.smAndDown ? true : false
                                  "
                                  class="btn"
                                  small
                                  outlined
                                  @click="setDetailsTax(item)"
                                >
                                  Detalhes
                                </v-btn>
                              </div>
                              <div
                                class="mr-2 mb-2"
                                v-if="isExpired(item.state)"
                              >
                                <v-btn
                                  :block="
                                    $vuetify.breakpoint.smAndDown ? true : false
                                  "
                                  class="btn"
                                  small
                                  outlined
                                  @click="secondTax(item)"
                                >
                                  Segunda via
                                </v-btn>
                              </div>
                              <div
                                class="mr-2 mb-2"
                                v-if="
                                  isOpen(item.state) || isExpired(item.state)
                                "
                              >
                                <v-btn
                                  :block="
                                    $vuetify.breakpoint.smAndDown ? true : false
                                  "
                                  class="btn"
                                  small
                                  outlined
                                  @click="confirmPay(item)"
                                >
                                  Já pagou?
                                </v-btn>
                              </div>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:no-data>
                    <v-col>
                      <p class="text-center my-5 body-1" style="color:#0288D1">
                        <v-icon color="#0288D1" class="mb-1" medium
                          >info</v-icon
                        >
                        Não há Impostos.
                      </p>
                    </v-col>
                  </template>
                  <template v-slot:no-results>
                    <v-col>
                      <p class="text-center my-5 body-1" style="color:#0288D1">
                        <v-icon color="#0288D1" class="mb-1" medium
                          >info</v-icon
                        >
                        Não há Impostos.
                      </p>
                    </v-col>
                  </template>
                </v-data-iterator>
              </v-col>
            </v-row>
          </v-row>
          <v-row
            v-if="filteredItems.length < 1"
            align="center"
            justify="center"
          >
            <v-col class="tax-due">
              <p class="text-center my-5 body-1" style="color:#0288D1">
                <v-icon color="#0288D1" class="mb-1" medium>info</v-icon>
                Não há Impostos.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
    <confirmPayment
      v-model="confirm"
      v-if="confirm"
      :dataTaxesProps="dataTaxes"
    ></confirmPayment>
    <detailsPaid
      v-model="details"
      v-if="details"
      :detailsTaxesProps="detailsTaxes"
    ></detailsPaid>
    <unavailableTax
      v-model="unavailable"
      v-if="unavailable"
      :unavailableTaxesProps="unavailableTaxes"
    >
    </unavailableTax>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import selectMonth from '@src/components/input/selectMonth.vue'
import confirmPayment from './confirmPayment.vue'
import unavailableTax from './unavailableTax.vue'
import detailsPaid from './detailsPaid.vue'
import { fileMethods } from '@state/helpers'

export default {
  name: 'Taxation',
  components: {
    confirmPayment,
    unavailableTax,
    detailsPaid,
    selectMonth,
  },
  data() {
    return {
      seeMoreFilters: true,
      details: false,
      detailsTaxes: {},
      unavailable: false,
      unavailableTaxes: {},
      dataTaxes: {},
      confirm: false,
      loadingTaxes: false,
      stateSelected: ['Em aberto', 'Pago', 'Vencido'],
      states: {
        open: {
          qtd: null,
          name: 'Em aberto',
          color: '#E9BF34',
        },
        paid: {
          qtd: null,
          name: 'Pago',
          color: '#0C9B1B',
        },
        expired: {
          qtd: null,
          name: 'Vencido',
          color: '#E93434',
        },
      },
      headerTitle: 'Gerenciar seus Impostos',
      headerDescription: `Confira os seus impostos, consulte os valores, datas de vencimento e não esqueça alterar os status
       e informar as datas de pagamento para não se perder! Importante: Esta área é destinada ao seu controle, não possuindo
       comunicação direta com os organismos oficiais`,

      rowsPerPage: -1,
      selectedMonth: [],
      listDateDueTaxes: [],
    }
  },
  computed: {
    ...mapGetters({
      taxes: 'taxations/taxes',
    }),

    // cria um objeto onde a chave é o vencimento dos impostos e o valor é uma lista dos impostos
    taxGroupByDue() {
      let orderedTaxList = this.orderByDate(this.taxes)
      let groupTax = orderedTaxList.reduce((accumulator = {}, object) => {
        let key = this.$moment(object.dueAt).format('YYYY-MM')
        object.stateColor = this.getColor(object.state)
        if (!accumulator[key]) {
          accumulator[key] = []
        }
        if (this.checkState(object)) {
          accumulator[key].push(object)
        }
        return accumulator
      }, {})

      return groupTax
    },

    filteredItems() {
      this.loadingTaxes = true
      setTimeout(() => {
        this.loadingTaxes = false
      }, 1000)

      this.listDateDueTaxes = Object.keys(this.taxGroupByDue).filter(
        (dateDue) => {
          return this.checkDate(dateDue)
        }
      )

      let listTaxes = []

      this.listDateDueTaxes.forEach((dateDue) => {
        listTaxes.push(Object.values(this.taxGroupByDue[dateDue]))
      })

      return listTaxes
    },

    totalState() {
      return {
        expired: this.taxes.filter((tax) => {
          return this.isExpired(tax.state) && this.checkDate(tax.dueAt)
        }).length,
        open: this.taxes.filter((tax) => {
          return this.isOpen(tax.state) && this.checkDate(tax.dueAt)
        }).length,
        paid: this.taxes.filter((tax) => {
          return this.isPaid(tax.state) && this.checkDate(tax.dueAt)
        }).length,
      }
    },
  },
  methods: {
    ...fileMethods,

    getColor(state) {
      if (this.isPaid(state)) return this.states.paid.color
      if (this.isExpired(state)) return this.states.expired.color
      if (this.isOpen(state)) return this.states.open.color
    },
    checkState(tax) {
      let stateTax = this.stateSelected.includes(tax.state)
      return stateTax
    },
    checkDate(date) {
      let dateFormat = this.$moment(date).format('YYYY-MM')
      let detaCompleted = this.selectedMonth
      return detaCompleted.includes(dateFormat)
    },
    cardClass(state) {
      let className = ''
      switch (state.toLowerCase()) {
        case 'pago':
          className += 'square-paid'
          break
        case 'em aberto':
          className += 'square-open'
          break
        case 'vencido':
          className += 'square-expired'
          break
        default:
          break
      }
      className += ' pa-3'
      return className
    },
    isExpired(state) {
      return state.toLowerCase() === 'vencido'
    },
    isPaid(state) {
      return state.toLowerCase() === 'pago'
    },
    isOpen(state) {
      return state.toLowerCase() === 'em aberto'
    },
    confirmPay(item) {
      this.confirm = true
      this.dataTaxes = item
    },
    secondTax(item) {
      this.unavailable = true
      this.unavailableTaxes = item
    },
    setDetailsTax(item) {
      this.details = true
      this.detailsTaxes = item
    },
    orderByDate(list) {
      return list.sort((a, b) => {
        return this.$moment(a.dueAt, ['YYYY-MM-DD HH:mm:ss']) >
          this.$moment(b.dueAt, ['YYYY-MM-DD HH:mm:ss'])
          ? -1
          : 1
      })
    },
  },
  async mounted() {
    this.loadingTaxes = true
    let month = this.$moment().month() + 1
    let year = this.$moment().year()
    for (month; month <= 12; month++) {
      this.selectedMonth.push(`${year}-${month.toString().padStart(2, '0')}`)
    }

    await this.$store.dispatch('taxations/getAll')
    this.loadingTaxes = false
  },
}
</script>

<style scoped>
.square-paid {
  border-radius: 20px;
  box-shadow: 3px 3px 8px hsl(126, 86%, 33%, 0.475) !important;
}
.square-open {
  border-radius: 20px;
  box-shadow: 3px 3px 8px hsla(46, 80%, 56%, 0.475) !important;
}
.square-expired {
  border-radius: 20px;
  box-shadow: 3px 3px 8px hsl(0, 80%, 56%, 0.475) !important;

  margin-left: 0;
}
.font {
  font-size: 12px;
}
.btn {
  border-radius: 10px;
  color: #707070;
}
.download-file {
  width: calc(100% + 2rem);
  height: calc(100% + 1rem);
  position: absolute;
  cursor: pointer;
  opacity: 0;
}
.tax-due {
  background-color: rgba(228, 228, 228, 0.144) !important;
}
</style>
