<template>
  <div>
    <v-form enctype="multipart/form-data">
      <div class="field ">
        <v-btn
          color="#024059"
          class="px-2 ml-0"
          outlined
          @click="activeProgress()"
        >
          <input
            type="file"
            ref="file"
            class="input-file"
            @change="selectFile()"
          />
          <v-progress-circular
            class="mr-2"
            :size="25"
            :indeterminate="progress"
            :width="2"
            style="z-index:2"
          >
            <v-icon>{{ icon }}</v-icon>
          </v-progress-circular>
          {{ labelFile }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
export default {
  props: ['valueFile'],

  data() {
    return {
      progress: false,
      icon: 'fa-arrow-alt-circle-up',
      textNoFile: false,
      file: null,
      labelFile: 'Selecionar arquivo',
    }
  },

  computed: {
    localValueFile: {
      get() {
        return this.valueFile
      },
      set(value) {
        this.file = value
        this.$emit('input', value)
      },
    },
  },

  methods: {
    activeProgress() {
      this.progress = true
      this.icon = ''
      setTimeout(() => {
        this.progress = false
        this.icon = 'fa-arrow-alt-circle-up'
        this.labelFile = 'Selecionar arquivo'
      }, 2000)
    },
    selectFile() {
      const file = this.$refs.file.files[0]
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = e.target.result
        const buffer = window.btoa(text)
        this.localValueFile = buffer
        this.progress = false
        this.icon = 'fa-check-circle'
        this.labelFile = 'Arquivo selecionado'
        this.textNoFile = false
      }
      reader.readAsBinaryString(file)
    },
  },
}
</script>
<style scoped>
.input-file {
  width: calc(100% + 2rem);
  height: calc(100% + 1rem);
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 4 !important;
}
</style>
