<template>
  <div class="ma-0 pa-0">
    <v-menu
      ref="menu"
      :close-on-content-click="false"
      v-model="menu"
      :nudge-right="0"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <i class="fa fa-calendar-check icon"></i>
        <input
          v-on="on"
          class="input-style"
          type="text"
          icon="event"
          v-model="dateFormatted"
          v-bind="$attrs.input || null"
        />
      </template>
      <v-date-picker
        @input="menu = false"
        v-model="valueLocal"
        no-title
        locale="pt-br"
        :disabled="disabled"
        :type="type"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'input-date',
  props: {
    value: {
      required: false,
    },
    required: {
      required: false,
      default: () => false,
    },
    placeholder: {
      required: false,
      default: 'Data',
    },
    disabled: {
      required: false,
      default: () => false,
    },
    rules: {
      type: Array,
      default: () => [],
      required: false,
    },
    type: {
      type: String,
      default: 'date',
      required: false,
    },
  },
  data() {
    return {
      menu: false,
      dateFormatted: this.formatDate(this.value),
    }
  },
  computed: {
    valueLocal: {
      get: function() {
        return this.value
      },
      set: function(value) {
        this.dateFormatted = this.formatDate(value)
        this.$emit('input', this.parseDate(value))
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      if (this.type.toLowerCase() === 'month')
        return this.$moment(date, [
          'DD-MM-YYYY',
          'MM-DD-YYYY',
          'YYYY-MM-DD',
          'MM-DD-YYYY H:m:s',
          'YYYY-MM-DD H:m:s',
        ]).format('MM/Y')
      return this.$moment(date, [
        'DD-MM-YYYY',
        'MM-DD-YYYY',
        'YYYY-MM-DD',
        'MM-DD-YYYY H:m:s',
        'YYYY-MM-DD H:m:s',
      ]).format('DD/MM/Y')
    },
    parseDate(date) {
      if (!date) return null
      let newV = this.$moment(date, [
        'DD-MM-YYYY',
        'MM-DD-YYYY',
        'YYYY-MM-DD',
        'MM-DD-YYYY H:m:s',
        'YYYY-MM-DD H:m:s',
      ]).format('Y-MM-DD')
      return newV
    },
  },
}
</script>

<style scoped>
.input-style {
  outline: 1px solid #024059;
  width: 88%;
  padding: 7px 18px;
  text-align: center;
  color: #024059;
  font-weight: bolder;
  cursor: pointer;
}
.input-style::placeholder {
  color: #024059;
  opacity: 1;
  padding-left: 20px;
}
i {
  padding-left: 15px;
  padding-top: 7px;
  position: absolute;
  color: #024059;
  font-size: 20px;
}
</style>
